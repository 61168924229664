import React from 'react';
import logo from './logo-digibot.png';
import fondo from './fondo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header" style={{ backgroundImage: `url(${fondo})` }}>
        <img src={logo} alt="logo" />
        <p>
         Bienvenido a Digibot
        </p>
        <p>Digibot se encuentra en construcción</p>

        <p>Encuéntranos en Instagram en <a href="https://www.instagram.com/digibot.cl">digibot.cl</a></p> 

      </header>
    </div>
  );
}

export default App;
